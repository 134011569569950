/*
* SETTINGS für die unterschiedlichen Shopware Zugangs Gruppen
* Mapping für Manufacturer und Sales Channels
* */
export default {
    "Bestell Admin AT" : {
        "sales_channel": '0befd5e4a5a0478fa80d52b137f9f817', // Leica Store Wien Seilergasse
        "manufacturer": ['d955cb37d1dc4cfebfcbcc6c7e206cd7'], // Leica Store Wien Seilergasse
        "taxId": '87b86591f42b41e2997d1353fa32ab57',
        "currency": "EUR",
        "shippingProvider": {
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s",
            "DHL": "https://www.dhl.com/at-en/home/tracking/tracking-express.html?tracking-id=%s"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin HQ": {
        "sales_channel": '67a8efc5541749f693e6c65bf5a3cac1',
        "manufacturer": ['5d18e0a183e6497496ff87a3fece3418'],
        "taxId": 'fa3247278e70497d82490ce7c1e50849',
        "currency": "EUR",
        "shippingProvider": {
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s",
            "DHL": "https://www.dhl.com/de-en/home/tracking/tracking-express.html?tracking-id=%s"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin IT": {
        "sales_channel": '921c6c50f097473f950b6b2d3fd1c08b',
        "manufacturer": ['7cde4fa99ca844a9b7716cf59cdaa72c',
                         'a425cc801d42435293e570b0a787c2a4',
                         'cd7e83957c6a4b6d99b979fe4c4217ed',
                         'fa3b71a34af54a5da3b96b7ab50f236a',
                         '3d249a0217fb40b8b5525f1692e37954'],
        "taxId": '5d39a9d72f3845948989fdef23ecaf1b',
        "currency": "EUR",
        "shippingProvider": {
            "UPS": "https://www.ups.com/track?loc=it_IT&requester=QUIC&tracknum=%s",
            "DHL": "https://www.dhl.com/it-it/home/tracciabilita.html?tracking-id=%s&submit=1"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin UK": {
        "sales_channel": '70ef07da0c2140188c2ad2280c4879d2',
        "manufacturer": ['6346dd0697db45da986293a041d67f5b'],
        "taxId": 'deb860e85e134994a306fccab5010a31',
        "currency": "GBP",
        "shippingProvider": {
            "DHL": "https://www.dhl.com/gb-en/home/tracking.html?tracking-id=%s&submit=1"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin AE": {
        "sales_channel": 'ee50c644eeaf4872a9ec0abd8dd3fc5c',
        "manufacturer": ['018ab2866817792da27f3973a01a95cc'],
        "taxId": '018abb9f371674539ff36e5981744b8b',
        "currency": "USD",
        "shippingProvider": {
            "DHL": "https://mydhl.express.dhl/ae/en/tracking.html#/results?id=%"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin ES": {
        "sales_channel": '611afdec2b8c4e17b9e10967554050fa',
        "manufacturer": ['018ab2d50c6e779b989210f7757927a5'],
        "taxId": 'e5511287f29044018d6c959669ae7d53',
        "currency": "EUR",
        "shippingProvider": {
            "DHL": "https://www.dhl.com/es-en/home/tracking.html?tracking-id=%s&submit=1"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin DK": {
        "sales_channel": 'b665131a132e44e8b74723202c221104',
        "manufacturer": ['018b81969d3c70648f943e334ce53180'],
        "taxId": '018ba5461d9572bda7f43a020d04edc3',
        "currency": "DKK",
        "shippingProvider": {
            "PostNord": "https://www.postnord.se/en/our-tools/track-and-trace?shipmentId=",
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin DE GmbH": {
        "sales_channel": '8fada63a562448529bf94d5445f1872d',
        "manufacturer": ['64fb848b545545ea93f2887014e0b9fa', '018b818fd4d9759487ec1272f94d7df2', '1a077a0e68cd4813a700e5a98e5f8387', '018b819ccb647a5c999a07359c0b839d', '018b818f188e7116833a09569f4eb805', '018b81982480759a87c8922326482295', '018b819b15e476dbb20a9338f48e70cb'],
        "taxId": 'fa3247278e70497d82490ce7c1e50849',
        "currency": "EUR",
        "shippingProvider": {
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s",
            "DHL": "https://www.dhl.com/de-en/home/tracking/tracking-express.html?tracking-id=%s"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin DE Leitz Park Marketing": {
        "sales_channel": '560587a70ce347f8b5f9f83533b8544d',
        "manufacturer": ['018b94006c4b76f9a269e77eb16d9ed6'],
        "taxId": 'fa3247278e70497d82490ce7c1e50849',
        "currency": "EUR",
        "shippingProvider": {
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s",
            "DHL": "https://www.dhl.com/de-en/home/tracking/tracking-express.html?tracking-id=%s"
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin PT": {
        "sales_channel": '0190538446d973a0bd7b66e69993b15d',
        "manufacturer": ['0190539b31227b5195ce6acf4455c6db'],
        "taxId": '0190538adfd073beb738d5b835e37e1f',
        "currency": "EUR",
        "shippingProvider": {
            "DHL": "https://www.dhl.com/es-en/home/tracking.html?tracking-id=%s&submit=1" // TODO
        },
        'allowDifferentialTaxation': true
    },
    "Bestell Admin US": {
        "sales_channel": '1613beca524540b4bcbfc165bbc5b336',
        "manufacturer": ['0190e4a4c62d75d0943d35fcb4ff7b8e','0190e489e0d574199da191ae5bec58ca','0190e4a53760795fb8f6630103c8c5de','0190e4a450b87a488fb3df2e946137fb','c27ce73a927c47fcbf063580ef787377'],
        "taxId": '2ef5f456d2a04b3f9657a2c4ad47781a',
        "currency": "USD",
        "shippingProvider": {
            "UPS": "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s"
        },
        'allowDifferentialTaxation': false
    },
    "Bestell Admin FR": {
        "sales_channel": '11b3c69aa98f47ea86517bbf765017da',
        "manufacturer": ['0190de2d357972d194df27b3823e0216','0190de2ec65274ce8eacf3535734c371','0190de28806973f381141936a4b9e0dd','0190de2b489d79d484a8e90560c85295','0190de25a3aa793b8584aa03b304c0ff'],
        "taxId": '3a11a3b0ce124f59a94d734ce753b5c0',
        "currency": "EUR",
        "shippingProvider": {
            "DHL": "https://www.dhl.com/fr-fr/home/tracking.html?tracking-id=%s&submit=1" // TODO
        },
        'allowDifferentialTaxation': true
    },
    //TODO Anlegen FR und US, noch keine Stores
    "currencyIDs": {
        "EUR": 'b7d2554b0ce847cd82f3ac9bd1c0dfca',
        "GBP": 'ccc3a0cdee7a4ef6bd7fff761cfdaa28',
        "USD": '03ee37799a01402f9d838d2fa8af781a',
        "DKK": '25d6a204fe4d471680a9f1e6772d3afb'
    },
    "sales_channels": {
        "Headless": "98432def39fc4624b33213a56b8c944d",
        "Leica Camera AG": "67a8efc5541749f693e6c65bf5a3cac1",
        "Leica Classics": "52366ced52c84f11b125bd07645d1a69",
        "Leica Classics IT": "921c6c50f097473f950b6b2d3fd1c08b",
        "Leica Classics UK": "70ef07da0c2140188c2ad2280c4879d2",
        "Leica Deutschland GmbH": "8fada63a562448529bf94d5445f1872d",
        "Leitz Park Marketing": "560587a70ce347f8b5f9f83533b8544d",
        "Leica Store Wien Seilergasse": "0befd5e4a5a0478fa80d52b137f9f817",
        "Leica Classics AE": "ee50c644eeaf4872a9ec0abd8dd3fc5c",
        "Leica Classic ES": "611afdec2b8c4e17b9e10967554050fa",
        "Leica Camera USA": "1613beca524540b4bcbfc165bbc5b336",
        "Leica Classics DK": "b665131a132e44e8b74723202c221104",
        "Leica Classics FR": "11b3c69aa98f47ea86517bbf765017da",
        "Classic Storefront": "018fb5710d8c73ad95d3b20f89d93e80",
        "Leica Classics PT": "0190538446d973a0bd7b66e69993b15d",
    },
    "taxIDs": { // TODO -> Tax IDs hier zu den RoleNames mappen wie bei AT (ausser bei diff)
        "diff": "a0fd894bcc7e4094a918690ca078d17e",
        "de": "fa3247278e70497d82490ce7c1e50849",
        "it": "5d39a9d72f3845948989fdef23ecaf1b",
        "en": "deb860e85e134994a306fccab5010a31",
        "es": "e5511287f29044018d6c959669ae7d53",
        "fr": "3a11a3b0ce124f59a94d734ce753b5c0",
        "ae": "018abb9f371674539ff36e5981744b8b",
        "dk": "018ba5461d9572bda7f43a020d04edc3",
        "pt": "0190538adfd073beb738d5b835e37e1f"
    },
    "language": { // sehr stabiler Bereich - um Requests zu sparen wird das mapping derzeit hartcodiert
        "de-DE": "ca973c7b65b74201a7250fd3bb6b6a0e",
        "en-GB": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
        "it-IT": "b8cda92fbc5f4bc991733d9b3169b54a",
        "fr-FR": "397aff4bd77b472e89c069067146dbc2",
        "es-ES": "61e61e5d118f4fe9a2bc312d325e8d0d",
        "zh-CN": "51ff255c913c4ed0a2a4277690f14f19"
    },
    "propertyGroup": { // ebenfalls stabil - um Requests zu sparen wird das mapping hartcodiert
        "condition": "dfa2af91515b4a5bb8042144942cd5c0",
        "manufactureYear": "91e1bf2a377a40e585bd98f12c248a3b",
        "guarantee": "c76caaeb48a94c08b6c9fb4e79978a64",
        "itemCategory": "018b4bd9d33375a68f6ca43d93704862",
        "color": "847e624fa2b548699c73f7fe304dcf17",
        "format": "997124d4af5b4a7e99387e9399ca568b",
        "special": "bed9e8d832914f619de72309a8e709ac",
        "technique": "75d14f2236f4422395bc412807033152"
    },
    "mediaFolderId": "e115381accbe48a799ae47055bb7cdc7",
    "leicaCategoryNodeId": "141b289b73aa48d4a16a094927f01891",
    "metaStrings": { // Anhänge für META Strings für alle Sprache
        "de": {
            title: " | Leica Camera Classic",
            description: " bei Leica Camera Classic! offizieller Vintage Store ✓ seltene und historische Kameras und Objektive ✓ Sammlerstücke ✓ versicherter und nachverfolgbarer Versand ✓"
        },
        "en": {
            title: " | Leica Camera Classic",
            description: " at Leica Camera Classic! Official Classic Store ✓ Rare and historical devices ✓ Collectibles ✓ Tracked and insured shipping ✓"
        },
        "it": {
            title: " | Leica Camera Classic",
            description: " su Leica Camera Classic! Classic Store Ufficiale ✓ Prodotti storici e rari ✓ Collezionismo ✓ Spedizione tracciata e assicurata ✓"
        },
        "es": {
            title: " | Leica Camera Classic",
            description: " ¡en Leica Camera Classic! Tienda oficial Classic ✓ Dispositivos raros e históricos ✓ Coleccionables ✓ Envío con seguimiento y asegurado ✓"
        },
        "fr": {
            title: " | Leica Camera Classic",
            description: " chez Leica Camera Classic ! Boutique officielle Classic ✓ Appareils rares et historiques ✓ Objets de collection ✓ Expédition suivie et assurée ✓"
        }
    },
    "defaultApprovedMailingRecipient": {
        mail: "dennis.iwaskiewicz@leica-camera.com",
        name: "Dennis Iwaskiewicz"
    },
    "avalaraTaxCodes": [
        {
            name: "Shipping",
            code: "FR020100"
        },
        {
            name: "Academy",
            code: "ST080000"
        },
        {
            name: "Cameras",
            code: "PH403706"
        },
        {
            name: "Accessoires",
            code: "PH403706"
        },
        {
            name: "Default",
            code: "P0000000"
        }
    ]
}

