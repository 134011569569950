import { createRouter, createWebHistory } from 'vue-router'
import {useAPI} from "@/composables/useAPI";

// 1. Define route components.
import LoginComponent from "@/components/LoginComponent";

// 2. Define some routes
// Each route should map to a component.
const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginComponent
    },
    {
        path: '/main',
        name: 'Layout',
        component: () => import ('@/components/MainLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('@/components/HomeComponent')
            },
            {
                path: 'productlist',
                name: 'Product List',
                component: () => import('@/components/ProductListComponent')
            },
            {
                path: 'productdetail/:productid',
                name: 'Product Detail',
                component: () => import('@/components/ProductDetailComponent')
            },
            {
                path: 'orderlist',
                name: 'Order List',
                component: () => import('@/components/OrderListComponent')
            },
            {
                path: 'stats',
                name: 'Statistics',
                component: () => import('@/components/StatsComponent')
            },
            {
                path: 'productListDT',
                name: 'Product List DT',
                component: () => import('@/components/ProductListDTComponent.vue')
            }
        ]

    },
];

// 3. Create the router instance and pass the `routes` option
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes, // short for `routes: routes`
})

router.beforeEach((to, from) => {
    const { currentUser } = useAPI()

    const isAuthenticated = Object.keys(currentUser.value).length > 0;

    if (!isAuthenticated && to.name !== 'Login') {
        // redirect the user to the login page
        return { name: 'Login' }
    }
});

